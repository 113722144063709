:root {
  --primary-color: #c2c2c3;
  --gradient-color: linear-gradient(180deg, #d2ff00 0%, #00d2ff 100%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Space Grotesk", sans-serif;
  background-color: #16181b;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 50px;
}

.social-links a {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 1.2rem;
  padding: 10px 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: background 0.3s, color 0.3s;
}

.social-links a:hover {
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
}

/* Custom */

h1 {
  color: #dbd56e;
  background: -webkit-linear-gradient(25deg, #eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3rem;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid;
  animation: type 5s steps(90) 1s infinite, cursor 1s step-end infinite;
  position: relative;
}

/* Keyframes */

@keyframes type {
  0% {
    width: 0;
  }

  50% {
    width: 100%;
  }

  80%, 100% {
    width: 100%;
  }
}

@keyframes cursor {
  50% {
    border-color: transparent;
  }
}

/* Media Queries for Responsiveness */

@media (max-width: 768px) {
  .container {
    padding: 20px;
  }

  .avatar {
    width: 100px;
    height: 100px;
  }

  h1 {
    font-size: 2rem;
  }

  .social-links a {
    font-size: 1rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .avatar {
    width: 80px;
    height: 80px;
  }

  h1 {
    font-size: 1.5rem;
  }

  .social-links a {
    font-size: 0.9rem;
    padding: 6px 12px;
  }
}